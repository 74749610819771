import axios from 'axios';
import { getJWT } from '../utils/firebase';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(async (config) => {
  const token = await getJWT();
  config.headers.env = process.env.REACT_APP_ENV;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}, (error) => Promise.reject(error));

axios.interceptors.response.use((response) => (response?.data), (error) => (Promise.reject(error)));

export default {
  createUser(body) {
    return axios.post('/users', body);
  },
  deleteUser(body) {
    return axios.put('/user-disable', body);
  },
  changePassword(uid, password) {
    return axios.put(`/users/${uid}`, { password });
  },
  syncAnalytics() {
    return axios.post('/sync-analytics');
  },
};
