import React from 'react';
import routes from '../utils/routes';
import Home from '../views/Home';
import Clients from '../views/Clients';
import ClientCreate from '../views/Clients/create';
import Offers from '../views/Offers';
import OffersCreate from '../views/Offers/create';
import Pricing from '../views/Config/pricing';
import Users from '../views/Config/users';
import StockPaper from '../views/Stock/Paper';
import StockPaperIn from '../views/Stock/Paper/in';
import StockPaperOut from '../views/Stock/Paper/out';
import Orders from '../views/Orders';
import OrderUpsert from '../views/Orders/upsert';
import PrintOrder from '../views/Print/order';
import Analytics from '../views/Analytics';

export default [
  { path: routes.HOME, element: <Home /> },
  { path: routes.CLIENTS, element: <Clients /> },
  { path: routes.CLIENT_CREATE, element: <ClientCreate /> },
  { path: routes.OFFERS, element: <Offers /> },
  { path: routes.OFFER_CREATE, element: <OffersCreate /> },
  { path: routes.CONFIG_PRICING, element: <Pricing /> },
  { path: routes.CONFIG_USERS, element: <Users /> },
  { path: routes.STOCK_PAPER, element: <StockPaper /> },
  { path: routes.STOCK_PAPER_IN, element: <StockPaperIn /> },
  { path: routes.STOCK_PAPER_OUT, element: <StockPaperOut /> },
  { path: routes.ORDERS, element: <Orders /> },
  { path: routes.ORDER_CREATE, element: <OrderUpsert /> },
  { path: routes.ORDER_DETAIL, element: <OrderUpsert /> },
  { path: routes.ANALYTICS, element: <Analytics /> },
];

export const print = [
  { path: routes.PRINT_ORDER, element: <PrintOrder /> },
];
