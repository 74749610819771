/* eslint-disable */
import { Card, Col, Row } from 'antd';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { GlobalContext } from '../../contexts/global';
import services from '../../services';
import collections from '../../utils/collections';
import { formatEuroNoDecimal } from '../../utils/common';
import { auth, getDocs, queryAll } from '../../utils/firebase';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const TOTAL_LABEL = 'Toplam Şirket';
const INIT_SET = {
  2024: {},
  2025: {},
};

export default function Analytics() {
  const { isAdmin, isTemsilci, isGrafik, getUserFullName } = useContext(GlobalContext);

  const [isLoading, setLoading] = useState(true);

  const [orderRevenueYearly, setOrderRevenueYearly] = useState(INIT_SET);
  const [orderRevenueMonthly, setOrderRevenueMonthly] = useState([]);
  const [orderCountYearly, setOrderCountYearly] = useState(INIT_SET);
  const [orderCountMonthly, setOrderCountMonthly] = useState([]);

  const [orderRevenueByMonth, setOrderRevenueByMonth] = useState([]); // Admin
  const [orderCountByMonth, setOrderCountByMonth] = useState([]); // Admin

  const [orderRevenueByMonthByRep, setOrderRevenueByMonthByRep] = useState([]);
  const [orderCountByMonthByRep, setOrderCountByMonthByRep] = useState([]);

  const [orderCountYearlyByTypeByGra, setOrderCountYearlyByTypeByGra] = useState(INIT_SET);
  const [orderCountMonthlyByTypeByGra, setOrderCountMonthlyByTypeByGra] = useState([]);

  async function getData() {
    if (auth.currentUser) {
      await services.syncAnalytics();
      await getDocs(queryAll(collections.ANALYTICS, undefined, 'lastUpdate'))
        .then((data) => {
          const revenueYearly = JSON.parse(JSON.stringify(orderRevenueYearly));
          const countYearly = JSON.parse(JSON.stringify(orderCountYearly));
          const countYearlyGra = JSON.parse(JSON.stringify(orderCountYearlyByTypeByGra));

          data.forEach(d => {
            Object.entries(d.sum.byRepresentative).forEach(([key, value]) => {
              const init = {
                title: getUserFullName(key) || TOTAL_LABEL,
                value: 0,
              };

              if (!revenueYearly[d.date.year][key]) revenueYearly[d.date.year][key] = { ...init };
              revenueYearly[d.date.year][key].value = revenueYearly[d.date.year][key].value + value.price;

              if (!countYearly[d.date.year][key]) countYearly[d.date.year][key] = { ...init };
              countYearly[d.date.year][key].value = countYearly[d.date.year][key].value + value.count;
            });

            Object.entries(d.sum.byGrapher).forEach(([key, value]) => {
              const init = {
                title: getUserFullName(key) || TOTAL_LABEL,
                digital: 0,
                flekso: 0,
              };

              if (!countYearlyGra[d.date.year][key]) countYearlyGra[d.date.year][key] = { ...init };
              countYearlyGra[d.date.year][key].digital = countYearlyGra[d.date.year][key].digital + (value.digital || 0);
              countYearlyGra[d.date.year][key].flekso = countYearlyGra[d.date.year][key].flekso + (value.flekso || 0);
            });
          });

          setOrderRevenueYearly(revenueYearly);
          setOrderCountYearly(countYearly);
          setOrderCountYearlyByTypeByGra(countYearlyGra);

          setOrderRevenueMonthly(data);

        }).finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    getData();
  }, [auth.currentUser]);

  function transformDataForChart(data) {
    const customOrder = [
      TOTAL_LABEL,
      "Sadık Balibeyoğlu",
      "Ege Erdem",
      "Tuna Yaşkın",
      "Dijital Satış",
    ];

    let labelsMap = new Map();
    let yearlyData = {};

    // Verileri yıllara göre ayrıştır
    for (const year in data) {
      yearlyData[year] = {};
      for (const key in data[year]) {
        const { title, value } = data[year][key];
        labelsMap.set(title, true);
        yearlyData[year][title] = value;
      }
    }

    // 📌 Öncelikli listeye göre sıralama fonksiyonu
    function customSort(a, b) {
      const indexA = customOrder.indexOf(a);
      const indexB = customOrder.indexOf(b);

      if (indexA === -1 && indexB === -1) {
        return a.localeCompare(b); // İkisi de listede yoksa alfabetik sırala
      }
      if (indexA === -1) return 1; // Listede olmayanlar en sona gider
      if (indexB === -1) return -1;
      return indexA - indexB; // Listede olanlar kendi sıralamalarına göre gider
    }

    // 📌 Labels dizisini özel sıralamaya göre oluştur
    let labels = Array.from(labelsMap.keys()).sort(customSort);

    // Dataset'leri oluştur
    const datasets = [];

    // 2025 verileri
    datasets.push({
      label: "2025",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
      data: labels.map(label => (yearlyData["2025"]?.[label] || 0))
    });

    // 2024 verileri
    datasets.push({
      label: "2024",
      backgroundColor: "rgba(75, 192, 192, 0.5)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
      data: labels.map(label => (yearlyData["2024"]?.[label] || 0))
    });

    return { labels, datasets };
  }

  function transformDataForChart2(data) {
    let labelsMap = new Map();
    let yearlyData = {};

    // Verileri yıllara göre ayrıştır
    for (const year in data) {
      yearlyData[year] = {};
      for (const key in data[year]) {
        const { title, digital, flekso } = data[year][key];
        labelsMap.set(title, true);
        yearlyData[year][title] = { digital, flekso };
      }
    }

    // Labels dizisini oluştur ("Toplam Şirket" en başta, geri kalan alfabetik)
    let labels = Array.from(labelsMap.keys())
      .filter(label => label !== "Toplam Şirket")
      .sort();

    labels.unshift("Toplam Şirket"); // "Toplam Şirket" en başta olsun

    // Dataset'leri oluştur
    const datasets = [];

    // Digital datasetleri oluştur
    datasets.push({
      label: "Digital 2024",
      backgroundColor: "rgba(75, 192, 192, 0.5)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
      data: labels.map(label => (yearlyData["2024"]?.[label]?.digital || 0)),
      hidden: true,
    });

    datasets.push({
      label: "Digital 2025",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
      data: labels.map(label => (yearlyData["2025"]?.[label]?.digital || 0))
    });

    // Flekso datasetleri oluştur
    datasets.push({
      label: "Flekso 2024",
      backgroundColor: "rgba(54, 162, 235, 0.5)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
      data: labels.map(label => (yearlyData["2024"]?.[label]?.flekso || 0)),
      hidden: true,
    });

    datasets.push({
      label: "Flekso 2025",
      backgroundColor: "rgba(255, 206, 86, 0.5)",
      borderColor: "rgba(255, 206, 86, 1)",
      borderWidth: 1,
      data: labels.map(label => (yearlyData["2025"]?.[label]?.flekso || 0))
    });

    return { labels, datasets };
  }

  function transformDataForChart3(data) {

    // 📌 Özel sıralama düzeni
    const customOrder = [
      "Toplam Şirket",
      "Sadık Balibeyoğlu",
      "Ege Erdem",
      "Tuna Yaşkın",
      "Dijital Satış"
    ];

    // 📌 `_` ID'sini "Toplam Şirket" olarak değiştir
    const repIdToName = (repId) => repId === "_" ? "Toplam Şirket" : getUserFullName(repId);

    // 📌 Ayları ve temsilcileri saklayacağımız obje
    const allMonths = new Set();
    const allRepresentatives = new Set();
    const monthlyData = {};

    // 📌 Veriyi işlerken hem ayları hem temsilcileri topla
    data.forEach(entry => {
      const { year, month } = entry.date;
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`;

      allMonths.add(monthKey);

      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = {};
      }

      Object.entries(entry.sum.byRepresentative).forEach(([repId, repData]) => {
        allRepresentatives.add(repId);
        monthlyData[monthKey][repId] = repData.count;
      });
    });

    // 📌 Temsilcileri özel sıralamaya göre düzenle
    const sortedRepresentatives = [...allRepresentatives]
      .map(repId => ({ id: repId, name: repIdToName(repId) })) // ID -> İsim dönüştür
      .sort((a, b) => {
        const indexA = customOrder.indexOf(a.name);
        const indexB = customOrder.indexOf(b.name);
        if (indexA === -1 && indexB === -1) return a.name.localeCompare(b.name); // İkisi de listede yoksa alfabetik sırala
        if (indexA === -1) return 1; // A listede yoksa sona at
        if (indexB === -1) return -1; // B listede yoksa sona at
        return indexA - indexB; // Listede olanlar için özel sıralamaya göre sırala
      });

    // 📌 Chart.js için format oluşturma
    const labels = [...allMonths].sort(); // X ekseni: Aylar (örn: "2025-01", "2025-02")
    const datasets = sortedRepresentatives.map(rep => ({
      label: rep.name, // 📌 Satış temsilcisi ismi dataset label olacak
      data: labels.map(monthKey => monthlyData[monthKey][rep.id] || 0), // 📌 Ay bazlı veri çek
      backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`
    }));

    // 📌 Chart.js konfigürasyonu
    return {
      labels,
      datasets
    };
  }

  function transformDataForChart4(data) {

    // 📌 Özel sıralama düzeni
    const customOrder = [
      "Toplam Şirket",
      "Sadık Balibeyoğlu",
      "Ege Erdem",
      "Tuna Yaşkın",
      "Dijital Satış"
    ];

    // 📌 `_` ID'sini "Toplam Şirket" olarak değiştir
    const repIdToName = (repId) => repId === "_" ? "Toplam Şirket" : getUserFullName(repId);

    // 📌 Ayları ve temsilcileri saklayacağımız obje
    const allMonths = new Set();
    const allRepresentatives = new Set();
    const monthlyData = {};

    // 📌 Veriyi işlerken hem ayları hem temsilcileri topla
    data.forEach(entry => {
      const { year, month } = entry.date;
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`;

      allMonths.add(monthKey);

      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = {};
      }

      Object.entries(entry.sum.byRepresentative).forEach(([repId, repData]) => {
        allRepresentatives.add(repId);
        monthlyData[monthKey][repId] = repData.price;
      });
    });

    // 📌 Temsilcileri özel sıralamaya göre düzenle
    const sortedRepresentatives = [...allRepresentatives]
      .map(repId => ({ id: repId, name: repIdToName(repId) })) // ID -> İsim dönüştür
      .sort((a, b) => {
        const indexA = customOrder.indexOf(a.name);
        const indexB = customOrder.indexOf(b.name);
        if (indexA === -1 && indexB === -1) return a.name.localeCompare(b.name); // İkisi de listede yoksa alfabetik sırala
        if (indexA === -1) return 1; // A listede yoksa sona at
        if (indexB === -1) return -1; // B listede yoksa sona at
        return indexA - indexB; // Listede olanlar için özel sıralamaya göre sırala
      });

    // 📌 Chart.js için format oluşturma
    const labels = [...allMonths].sort(); // X ekseni: Aylar (örn: "2025-01", "2025-02")
    const datasets = sortedRepresentatives.map(rep => ({
      label: rep.name, // 📌 Satış temsilcisi ismi dataset label olacak
      data: labels.map(monthKey => monthlyData[monthKey][rep.id] || 0), // 📌 Ay bazlı veri çek
      backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`
    }));

    // 📌 Chart.js konfigürasyonu
    return {
      labels,
      datasets
    };
  }

  function transformDataForChart5(data) {
    const rawData = data; // JSON string'ini parse et
    // 📌 Ay ve tasarımcıları saklayacağımız obje
    const allMonths = new Set();
    const allGraphers = new Set();
    const monthlyData = {};

    // 📌 Veriyi işlerken hem ayları hem de tasarımcıları listele
    rawData.forEach(entry => {
      const { year, month } = entry.date;
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`;

      allMonths.add(monthKey);

      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = {};
      }

      Object.entries(entry.sum.byGrapher).forEach(([grapherId, grapherData]) => {
        allGraphers.add(grapherId);
        if (!monthlyData[monthKey][grapherId]) {
          monthlyData[monthKey][grapherId] = { digital: 0, flekso: 0 };
        }
        monthlyData[monthKey][grapherId].digital = grapherData.digital || 0;
        monthlyData[monthKey][grapherId].flekso = grapherData.flekso || 0;
      });
    });

    // 📌 Grafik tasarımcıları alfabetik sırayla sıralayalım
    const sortedGraphers = [...allGraphers].map(grapherId => ({
      id: grapherId,
      name: grapherId === "_" ? "Toplam Şirket" : getUserFullName(grapherId)
    })).sort((a, b) => a.name.localeCompare(b.name));

    // 📌 Chart.js için format oluşturma
    const labels = [...allMonths].sort(); // X ekseni: Aylar (örn: "2025-01", "2025-02")
    const datasets = [];

    // 📌 Grafik tasarımcı bazlı dijital ve flekso datasetleri oluştur
    sortedGraphers.forEach(grapher => {
      datasets.push({
        label: `${grapher.name} - Dijital`,
        data: labels.map(monthKey => (monthlyData[monthKey][grapher.id]?.digital) || 0),
        backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 255, 0.6)`,
      });
      datasets.push({
        label: `${grapher.name} - Flekso`,
        data: labels.map(monthKey => (monthlyData[monthKey][grapher.id]?.flekso) || 0),
        backgroundColor: `rgba(255, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
      });
    });

    // 📌 Chart.js konfigürasyonu
    return {
      labels, // Ay bazlı X ekseni
      datasets // Grafik tasarımcı bazlı datasetler
    };
  }

  function transformDataForChart6(data) {
    const rawData = data;

    // 📌 Aylar ve grafik tasarımcıları saklayacağımız obje
    const allMonths = new Set();
    const allGraphers = new Set();
    const monthlyData = {};

    // 📌 Veriyi işlerken hem ayları hem de grafik tasarımcıları listele
    rawData.forEach(entry => {
      const { year, month } = entry.date;
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`;

      allMonths.add(monthKey);

      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = {};
      }

      Object.entries(entry.sum.byGrapher).forEach(([grapherId, grapherData]) => {
        allGraphers.add(grapherId);
        if (!monthlyData[monthKey][grapherId]) {
          monthlyData[monthKey][grapherId] = { digital: 0, flekso: 0 };
        }
        monthlyData[monthKey][grapherId].digital = grapherData.digital || 0;
        monthlyData[monthKey][grapherId].flekso = grapherData.flekso || 0;
      });
    });

    // 📌 Grafik tasarımcıları alfabetik sırayla sıralayalım
    // const sortedGraphers = [...allGraphers].map(grapherId => ({
    //   id: grapherId,
    //   name: grapherId === "_" ? "Toplam Şirket" : getUserFullName(grapherId)
    // })).sort((a, b) => a.name.localeCompare(b.name));
    const sortedGraphers = [...allGraphers].map(grapherId => ({
      id: grapherId,
      name: grapherId === "_" ? "Toplam Şirket" : getUserFullName(grapherId)
    })).sort((a, b) => {
      if (a.name === "Toplam Şirket") return -1;
      if (b.name === "Toplam Şirket") return 1;
      return a.name.localeCompare(b.name);
    });

    // 📌 Kullanılacak renkleri tanımla
    const graphColors = [
      { digital: "rgba(54, 162, 235, 1", flekso: "rgba(54, 162, 235, 0.5)" },   // Mavi tonları
      { digital: "rgba(255, 99, 132, 1", flekso: "rgba(255, 99, 132, 0.5)" },   // Kırmızı tonları
      { digital: "rgba(255, 206, 86, 1", flekso: "rgba(255, 206, 86, 0.5)" },   // Sarı tonları
      { digital: "rgba(75, 192, 192, 1", flekso: "rgba(75, 192, 192, 0.5)" },   // Turkuaz tonları
      { digital: "rgba(153, 102, 255, 1", flekso: "rgba(153, 102, 255, 0.5)" }  // Mor tonları
    ];

    // 📌 Grafik tasarımcısına renk atamak için fonksiyon
    const getColorForGrapher = (index) => graphColors[index % graphColors.length];

    // 📌 Chart.js için format oluşturma
    const labels = [...allMonths].sort(); // X ekseni: Aylar (örn: "2025-01", "2025-02")
    const datasets = [];

    // 📌 Her grafik tasarımcı için tek dataset oluştur
    sortedGraphers.forEach((grapher, index) => {
      const colors = getColorForGrapher(index);

      datasets.push({
        label: grapher.name,
        data: labels.flatMap(monthKey => [
          monthlyData[monthKey][grapher.id]?.digital || 0,
          monthlyData[monthKey][grapher.id]?.flekso || 0
        ]),
        backgroundColor: labels.flatMap(() => [
          colors.digital, // Dijital için açık ton
          colors.flekso   // Flekso için koyu ton
        ]),
        hidden: grapher.name === TOTAL_LABEL,
      });
    });

    // 📌 Chart.js konfigürasyonu
    return {
      labels: labels.flatMap(month => [month + " - Dijital", month + " - Flekso"]),
      datasets
    };
  }

  const options = {
    indexAxis: 'y',
    responsive: true,
    minBarLength: 5,
    scales: {
      y: {
        ticks: {
          font: {
            weight: '600',
            size: 13
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            weight: '600',
            size: 13
          }
        }
      },
      title: {
        display: true,
        text: 'Satış Temsilcisi Bazında Baskı Onayı Alınan İş Cirosu',
        font: {
          weight: '600',
          size: 14
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => formatEuroNoDecimal(Math.floor(value)),
        font: {
          weight: '600',
          size: 13
        }
      },
    },
  };

  const options2 = {
    indexAxis: 'y',
    responsive: true,
    minBarLength: 5,
    scales: {
      y: {
        ticks: {
          font: {
            weight: '600',
            size: 13
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            weight: '600',
            size: 13
          }
        }
      },
      title: {
        display: true,
        text: 'Satış Temsilcisi Bazında Baskı Onayı Alınan İş Adedi',
        font: {
          weight: '600',
          size: 14
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        font: {
          weight: '600',
          size: 13
        }
      },
    },
  };

  const options3 = {
    indexAxis: 'x',
    responsive: true,
    minBarLength: 5,
    scales: {
      y: {
        ticks: {
          font: {
            weight: '600',
            size: 13
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            weight: '600',
            size: 13
          }
        }
      },
      title: {
        display: true,
        text: 'Grafik Tasarımcı / Baskı Türü Bazında Üretilen İş Adedi',
        font: {
          weight: '600',
          size: 14
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        font: {
          weight: '600',
          size: 13
        }
      },
    },
  };

  const options4 = {
    indexAxis: 'x',
    responsive: true,
    minBarLength: 5,
    scales: {
      y: {
        ticks: {
          font: {
            weight: '600',
            size: 13
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            weight: '600',
            size: 13
          }
        }
      },
      title: {
        display: true,
        text: 'Aylık Baskı Onayı Alınan İş Adedi',
        font: {
          weight: '600',
          size: 14
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        font: {
          weight: '600',
          size: 13
        }
      },
    },
  };

  const options5 = {
    indexAxis: 'x',
    responsive: true,
    minBarLength: 5,
    scales: {
      y: {
        ticks: {
          font: {
            weight: '600',
            size: 13
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            weight: '600',
            size: 13
          }
        }
      },
      title: {
        display: true,
        text: 'Aylık Baskı Onayı Alınan İş Cirosu',
        font: {
          weight: '600',
          size: 14
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => formatEuroNoDecimal(Math.floor(value)),
        font: {
          weight: '600',
          size: 13
        }
      },
    },
  };

  const options6 = {
    indexAxis: 'x',
    responsive: true,
    minBarLength: 5,
    scales: {
      y: {
        ticks: {
          font: {
            weight: '600',
            size: 13
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            weight: '600',
            size: 13
          }
        }
      },
      title: {
        display: true,
        text: 'Aylık Grafik Tasarımcı / Baskı Türü Bazında Üretilen İş Adedi',
        font: {
          weight: '600',
          size: 14
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        font: {
          weight: '600',
          size: 13
        }
      },
    },
  };

  const options7 = {
    indexAxis: 'x',
    responsive: true,
    minBarLength: 5,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            weight: '600',
            size: 13
          }
        }
      },
      title: {
        display: true,
        text: 'Aylık Grafik Tasarımcı / Baskı Türü Bazında Üretilen İş Adedi',
        font: {
          weight: '600',
          size: 14
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        font: {
          weight: '600',
          size: 13
        }
      },
    },
    scales: {
      x: {
        stacked: false,
        font: {
          weight: '600',
          size: 13
        }
      },
      y: {
        stacked: false,
        font: {
          weight: '600',
          size: 13
        }
      }
    },
  };

  return (
    <Card title="Analytics" bordered={false} loading={isLoading}>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          <div className="chart-wrapper">
            <Bar options={options} data={transformDataForChart(orderRevenueYearly)} />
          </div>
          <br />
          <br />
        </Col>
        <Col className="gutter-row" span={24}>
          <div className="chart-wrapper">
            <Bar options={options5} data={transformDataForChart4(orderRevenueMonthly)} />
          </div>
          <br />
          <br />
        </Col>
        <Col className="gutter-row" span={24}>
          <div className="chart-wrapper">
            <Bar options={options2} data={transformDataForChart(orderCountYearly)} />
          </div>
          <br />
          <br />
        </Col>
        <Col className="gutter-row" span={24}>
          <div className="chart-wrapper">
            <Bar options={options4} data={transformDataForChart3(orderRevenueMonthly)} />
          </div>
          <br />
          <br />
        </Col>
        <Col className="gutter-row" span={24}>
          <div className="chart-wrapper">
            <Bar options={options3} data={transformDataForChart2(orderCountYearlyByTypeByGra)} />
          </div>
          <br />
          <br />
        </Col>
        {/* <Col className="gutter-row" span={24}>
          <div className="chart-wrapper">
            <Bar options={options6} data={transformDataForChart5(orderRevenueMonthly)} />
          </div>
          <br />
          <br />
        </Col> */}
        <Col className="gutter-row" span={24}>
          <div className="chart-wrapper">
            <Bar options={options7} data={transformDataForChart6(orderRevenueMonthly)} />
          </div>
          <br />
          <br />
        </Col>
      </Row>
    </Card>
  );
}
