export default {
  CLIENTS: 'clients',
  OFFERS: 'offers',
  USERS: 'users',
  PAPERS: 'pricing_papers',
  INKS: 'pricing_inks',
  OTHERS: 'pricing_others',
  STOCK_PAPER: 'stock_paper',
  STOCK_PAPER_TYPES: 'stock_paper_types',
  STOCK_PAPER_OUT: 'stock_paper_out',
  ORDERS: 'orders',
  ANALYTICS: 'analytics',
};
